<template>

  <div :class="'cmnt ' + type">
    <textarea 
      ref="area"
      :placeholder="placeholder" 
      cols="30" 
      :rows="rows" 
      v-model="edited_text"
      @keyup="change"
      ></textarea>

      <div class="emoji_ic" @click="toggleEmoji"><i class="icon-smile"></i></div>
      
      <div class="emoji_cn" v-if="emoji_active">
         <span
          v-for="(e,ek) in emoji"
          :key="ek"
          @click="insertEmoji(e)"
          >{{ e }}</span>        
      </div> 

        
  </div>

</template>


<script>
  
 
export default {
  components: { 

  },
  props: {
    text: {
      type: String
    }, 
    type: {
      type: String,
      default: ''
    }, 
    rows: {
      type: Number,
      default: 10,
    }, 
    placeholder: {
      type: String
    }, 
    max: {
      type: Number,
      default: null
    },
    focusMounted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {       
      edited_text: this.text,
      emoji_active: false,
      emoji: [        
        "😀","😬","😁","😂","😃","😄","😅","😆","😇","😉","😊","😋","😌","😍","😘","😗","😙","😚","😜","😝","😛","😎","😏","😶","😐","😑","😒","😳","😞","😟","😠","😡","😔","😕","😣","😖","😫","😩","😤","😮","😱","😨","😰","😯","😦","😧","😢","😥","😪","😓","😭","😵","😲","😷","😴","💤","💩","😈","👿","👹","👺","💀","👻","👽","😺","😸","😹","😻","😼","😽","🙀","😿","😾","🙌","👏","👋","👍","👊","✊","👌","✋","💪","🙏","👆","👇","👈","👉"
      ],
    };
  },
  created(){
    
  },
  watch: {
    text(val){
      this.edited_text = val;
    }
  },
  computed: {     
    // txt(){
    //   return SpecCharsFilter.decode(EmojiFilter.textToEmoji(this.text));
    // }
  },
  mounted(){
    if(this.focusMounted){
      this.$refs.area.focus();
    }
  },
  methods: { 
    change(){
      if (this.max && this.edited_text.length > this.max) {
        this.edited_text = this.edited_text.substring(0, this.max);
      }
      this.$emit('change:text', this.edited_text);
    },
    insertEmoji(text) {
      var txtarea = this.$refs.area;
      var scrollPos = this.$refs.area.scrollTop;
      var strPos = 0;
      var br = ((this.$refs.area.selectionStart || this.$refs.area.selectionStart == '0') ?
        "ff" : (document.selection ? "ie" : false));
      if (br == "ie") {
        this.$refs.area.focus();
        var range = document.selection.createRange();
        range.moveStart('character', -this.edited_text.length);
        strPos = range.text.length;
      } else if (br == "ff") strPos = this.$refs.area.selectionStart;

      var front = (this.edited_text).substring(0, strPos);
      var back = (this.edited_text).substring(strPos, this.edited_text.length);
      this.edited_text = front + text + back;
      strPos = strPos + text.length;
      setTimeout(()=>{

        if (br == "ie") {
          this.$refs.area.focus();
          var range = document.selection.createRange();
          range.moveStart('character', -this.edited_text.length);
          range.moveStart('character', strPos);
          range.moveEnd('character', 0);
          range.select();
        } else if (br == "ff") {
          this.$refs.area.selectionStart = strPos;
          this.$refs.area.selectionEnd = strPos;
          this.$refs.area.focus();
        }
        
        this.$refs.area.scrollTop = scrollPos;
        
      },100)

      this.change();
    },
    toggleEmoji(){
      this.emoji_active = !this.emoji_active;
    }
  }
}
</script>
   

<style scoped>
.cmnt{
  position: relative;
}
.emoji_ic{
    font-size: 1.5rem;
    position: absolute;
    right: 10px;
    margin-top: -40px;
  cursor: pointer;
}
.emoji_cn{
  margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.emoji_cn > span{
  font-size: 1.4rem;
  cursor: pointer;
  transition: 300ms;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emoji_cn > span:hover{
  font-size: 1.9rem;
}


@media (max-width: 768px) {
   .emoji_ic,
   .emoji_cn{
     display: none;
   }
}

</style>
